function SettingMetaApi({ lang, confirmDialog, business_data, toast, chatCenter, setChatCenter }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isGetLoading, setIsGetLoading] = React.useState(false);
  const [instagramError, setInstagramError] = React.useState(false);
  const [getInstagramPage, setGetInstagramPage] = React.useState([]);
  const [getFacebookPage, setGetFacebookPage] = React.useState([]);
  const [maxPage, setMaxPage] = React.useState(0);
  const [isConnected, setIsConnected] = React.useState(false);
  const [show, setShow] = React.useState(false);
  let isMobile = window.innerWidth < 768;

  React.useEffect(() => {
    if (FB) {
      FB.init({
        appId: main.facebook_app_id,
        status: true,
        cookie: true,
        xfbml: true,
        version: "v16.0",
      });
      getSettingAccount();
    } else {
      setInstagramError(true);
      setIsLoading(false);
    }
  }, []);

  const getSettingAccount = () => {
    setIsGetLoading(true);
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url:
          backend +
          "setting/" +
          business_data.uid +
          "/" +
          business_data.app_id +
          "/instagram-api/get",
        headers: {
          "Content-type": "application/json;charset=UTF-8",
          "X-VC-Access-Token": business_data.access,
          Authorization: "Bearer " + business_data.token,
        },
      }).then((res) => {
        if (!res.data.result) {
          settingChatCenter(res.data.facebook_data, res.data.instagram_data);
          setGetInstagramPage(res.data.instagram_data);
          setGetFacebookPage(res.data.facebook_data);
          setMaxPage(res.data.max_page);
        } else {
          reject();
        }
        setIsGetLoading(false);
        setIsLoading(false);
      });
    });
  };

  const settingChatCenter = (facebook, instagram) => {
    let objectChatCenterInstagram = {};
    let objectChatCenterFacebook = {};
    if(Array.isArray(instagram)) {
      instagram.forEach((item) => {
        objectChatCenterInstagram[item.account_id] = {
          id: item.account_id,
          name: item.title,
          type: "instagram",
          connection_status: item.connection_status ? true : false,
        };
      });
    } if (Array.isArray(facebook)) {
      facebook.forEach((item) => {
        objectChatCenterFacebook[item.page_id] = {
          id: item.page_id,
          name: item.title,
          type: "facebook",
          connection_status: item.connection_status ? true : false
        };
      });
    }

    chatCenter = _.omitBy(chatCenter, (value) => value.type === "instagram" || value.type === "facebook");
    chatCenter = Object.assign({}, objectChatCenterInstagram, objectChatCenterFacebook, chatCenter);
  
    setChatCenter(chatCenter);
  }

  return (
    <>
      {isLoading ? (
        <div class="sp-placeholder">
          <div class="sp-text-help">
            <i class="icon icon-circle-notch animate-spin"></i>{" "}
            <span>{lang.__loading}</span>
          </div>
        </div>
      ) : instagramError ? (
        <div class="sp-placeholder">
          <div class="sp-text-help">
            <span>{lang.__facebook_setting_error}</span>
          </div>
        </div>
      ) : (
        <>
          <div
            class="sp-row"
            style={{
              display: "flex",
              alignItems: "center",
              height: "73px",
              padding: "0 20px",
              borderBottom: "1px solid rgb(225, 231, 242)",
            }}
          >
            <div>
              <span class="sp-label">{lang.__setting_meta_api}</span>
              <ReactTooltip
                text={lang.__manual_setting_meta_api}
                tooltipWidth={160}
              >
                <a
                  href="https://help.readyplanet.com/rchat/chat-center/connect-with-meta"
                  target="_blank"
                >
                  <i
                    class="icon-help-circled sp-link"
                    tooltip-placement="right"
                  ></i>
                </a>
              </ReactTooltip>
              <p class="sp-text-help">{lang.__setting_meta_api_description}</p>
            </div>
          </div>
          {isGetLoading ? (
            <div class="sp-placeholder">
              <div class="sp-text-help">
                <i class="icon icon-circle-notch animate-spin"></i>{" "}
                <span>{lang.__loading}</span>
              </div>
            </div>
          ) : (
            <div
              class="body -space-between"
              style={{ margin: isMobile ? "0 2%" : "0 8%" }}
            >
              <div
                style={{
                  marginTop: 30,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  class="sp-btn -blue"
                  style={{ width: 136, height: 40 }}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  {lang.__rapid_reply_manage}
                </button>
              </div>

              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <div
                  style={{
                    width: isMobile ? "100%" : "50%",
                    marginRight: !isMobile && 20,
                  }}
                >
                  <div
                    class="sp-list-header sp-row -align-middle -space-between"
                    style={{
                      paddingBottom: 6,
                      borderBottom: "1px solid rgb(225, 231, 242)",
                    }}
                  >
                    <span class="sp-label">
                      <img
                        src="../public/img/facebook.png"
                        width={20}
                        height={20}
                        style={{ marginRight: 10 }}
                      />
                      <span>{lang.__facebook_pages} </span>
                      <span>
                        ({getFacebookPage.length}/{maxPage})
                      </span>
                    </span>
                  </div>
                  <div
                    class="sp-list -box"
                    style={{
                      padding: "0px",
                      maxHeight: "70vh",
                      overflow: "auto",
                      scrollBehavior: "smooth",
                    }}
                  >
                    {!getFacebookPage.length ? (
                      <div class="sp-text-help" style={{ textAlign: "center" }}>
                        {lang.__no_connection}
                      </div>
                    ) : null}
                    {getFacebookPage.map((item, index) => {
                      return (
                        <div class="item"
                        style={{ border: item.warning && "1px solid red" }}>
                          <div class="sp-row -gap -align-middle">
                            <div class="sp-col -ellipsis">
                              <span>{item.title}</span>
                            </div>
                            {item.warning ? (
                              <div
                                class="sp-col -wrap"
                                style={{ verticalAlign: "middle" }}
                              >
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: 12,
                                    fontWeight: "small",
                                  }}
                                >
                                  <i class="icon icon-warning-sign "></i>
                                  {lang.__connection_have_problem}
                                  <span
                                    class="sp-link"
                                    style={{
                                      marginLeft: 4,
                                      textDecoration: "underline",
                                    }}
                                    onClick={() => {
                                      setShow(true);
                                    }}
                                  >
                                    {lang.__fix}
                                  </span>
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  style={{
                    width: isMobile ? "100%" : "50%",
                    marginTop: isMobile && 20,
                  }}
                >
                  <div
                    class="sp-list-header sp-row -align-middle -space-between"
                    style={{
                      paddingBottom: 6,
                      borderBottom: "1px solid rgb(225, 231, 242)",
                    }}
                  >
                    <span class="sp-label">
                      <img
                        src="../public/img/instagram_icon.png"
                        width={20}
                        height={20}
                        style={{ marginRight: 10 }}
                      />
                      <span>{lang.__instagram_account} </span>
                      <span>
                        ({getInstagramPage.length}/{maxPage})
                      </span>
                    </span>
                  </div>
                  <div
                    class="sp-list -box"
                    style={{
                      padding: "0px",
                      maxHeight: "70vh",
                      overflow: "auto",
                      scrollBehavior: "smooth",
                    }}
                  >
                    {!getInstagramPage.length ? (
                      <div class="sp-text-help" style={{ textAlign: "center" }}>
                        {lang.__no_connection}
                      </div>
                    ) : null}
                    {getInstagramPage.map((item, index) => {
                      return (
                        <div
                          class="item"
                          style={{ border: item.warning && "1px solid red" }}
                        >
                          <div class="sp-row -gap -align-middle">
                            <div class="sp-col -ellipsis">
                              <span>{item.title}</span>
                            </div>
                            {item.warning ? (
                              <div class="sp-col -wrap">
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: 12,
                                    fontWeight: "small",
                                  }}
                                >
                                  <i class="icon icon-warning-sign "></i>
                                  {lang.__connection_have_problem}
                                  <span
                                    class="sp-link"
                                    style={{
                                      marginLeft: 4,
                                      textDecoration: "underline",
                                    }}
                                    onClick={() => {
                                      setShow(true);
                                    }}
                                  >
                                    {lang.__fix}
                                  </span>
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
          <MetaDialog
            show={show}
            setShow={setShow}
            lang={lang}
            isConnected={isConnected}
            setIsConnected={setIsConnected}
            business_data={business_data}
            toast={toast}
            getInstagramPage={getInstagramPage}
            setGetInstagramPage={setGetInstagramPage}
            getSettingAccount={getSettingAccount}
            getFacebookPage={getFacebookPage}
            setGetFacebookPage={setGetFacebookPage}
            maxPage={maxPage}
            settingChatCenter={settingChatCenter}
          />
        </>
      )}
    </>
  );
}
